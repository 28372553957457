<template>
  <div v-if="dialog">

    <v-dialog
        :value="dialog"
        persistent
        transition="dialog-bottom-transition"
    >
    
      <v-card >
        <v-toolbar >
          <v-btn icon  @click="$emit('onCloseDialog')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title> {{ !configUserData._id ? $t('title.create_configUser') :$t('title.update_configUser')  }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn
                v-if="loading"
                disabled
                
                text
                @click="validate"
            >
              {{ !configUserData._id ? $t('button.create') : $t('button.update') }}
            </v-btn>
            <v-btn
                v-else
                
                text
                @click="validate"
            >
              {{ !configUserData._id ? $t('button.create') : $t('button.update') }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <Loading :visible="loading"/>
        <v-card-text>
          <v-container>
            <v-form
                ref="form"
                v-model="valid"
                lazy-validation
            >
              <v-row>
                  <v-select
                    disabled
                    v-model="configUserData.currency_type"
                    :items="items"
                    item-text="name"
                    item-value="_id"
                    :label="$t('input.chose_type')"
                  ></v-select>
              </v-row>
              <v-row class="mt-5">
                <v-text-field
                    v-model="configUserData.default_bet_a_fight"
                    :label="$t('input.default_bet_a_fight')"
                    :rules="rules.default_bet_a_fight"
                    type="number"
                />
              </v-row>
              <v-row >
                  <v-text-field
                    v-model="configUserData.default_bet_win_a_day"
                    :label="$t('input.default_bet_win_a_day')"
                    :rules="rules.default_bet_win_a_day"
                    type="number"
                ></v-text-field>
              </v-row>
                <v-row class="mt-5">
                <v-text-field
                    v-model="configUserData.agent_bet_a_fight"
                    :label="$t('input.agent_bet_a_fight')"
                    :rules="rules.agent_bet_a_fight"
                    type="number"
                />
              </v-row>
              <v-row >
                  <v-text-field
                    v-model="configUserData.agent_bet_win_a_day"
                    :label="$t('input.agent_bet_win_a_day')"
                    :rules="rules.agent_bet_win_a_day"
                    type="number"
                ></v-text-field>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {

  props: {
    dialog: {
      type: Boolean,
      default: false
    },
    darkmode: {
      type: Boolean
    },
    optionRoles: {
      type: Array,
      default: () => {
        return []
      }
    },
    loading: {
      type: Boolean
    },
    rules: {
      type: Object,
      default: function () {
        return {
          total_bet_win_a_day: [
            v => !!v || 'Name is required',
          ],
          total_bet_a_fight: [
            v => !!v || 'description is required',
          ],
        }
      }
    },
    isValidate: {
      type: Boolean,
      default: true
    },
    configUserData: {type: Object, default: null},
    users: {
      type: Array,
       default: null
    },
    
  },
  computed: {
    valid: {
      // getter
      get: function () {
        return this.isValidate
      },
      // setter
      set: function (newValue) {
        this.$emit('update:isValidate', newValue)
      }
    }
  },
  data: () => {
    return {
      showPassword: false,
      items:[
        { _id:1,
          name:"លុយដុល្លា - Usd"
        },
        { _id:2,
          name:"លុយរៀល - Khmer"
        },
        { _id:3,
          name:"លុយបាត - Baht"
        },
        ],
    }
  },
  watch: {
    optionRoles:function(newVal){ 
      this.users.role_id = newVal[0]
    },
    // users:function(newVal, oldVal){ 
    // }
  },
  methods: {
    validate() {
      if (this.$refs.form.validate()) {
        this.$emit('submit')
      }
    },
  }
}

</script>
